import { keyframes } from 'styled-components';

export const slowDescent = keyframes`
  0% { transform: translateY(-6rem); opacity: 0 }
  50% { transform: translateY(-6rem); opacity: 0 }
  100% { transform: translateY(0); opacity: 1 }
`;

export const slowBounce = keyframes`
  0% { transform: translateY(-6rem); opacity: 0 }
  70% { transform: translateY(2rem); opacity: 1 }
  100% { transform: translateY(0); opacity: 1 }
`;

export const slideInLeft = keyframes`
  0% { transform: translateX(6rem); opacity: 0 }
  50% { transform: translateX(3rem); opacity: 0 }
  100% { transform: translateX(0); opacity: 1 }
`;

export const slideInRight = keyframes`
  0% { transform: translateX(-6rem); opacity: 0 }
  50% { transform: translateX(-3rem); opacity: 0 }
  100% { transform: translateX(0); opacity: 1 }
`;
