import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

export default function getPortfolioImages() {
  const {
    tenguPicturePlaceholderDesktop,
    tenguPicturePlaceholderTablet,
    tenguPicturePlaceholderMobile,
    riverPicturePlaceholderDesktop,
    riverPicturePlaceholderTablet,
    riverPicturePlaceholderMobile,
    girlBirdPicturePlaceholderDesktop,
    girlBirdPicturePlaceholderTablet,
    girlBirdPicturePlaceholderMobile,
    codingPicPlaceholder,
    css3LogoPlaceholder,
    html5LogoPlaceholder,
    cypressLogoPlaceholder,
    javascriptLogoPlaceholder,
    jestLogoPlaceholder,
    nodeLogoPlaceholder,
    reactLogoPlaceholder,
    reduxLogoPlaceholder,
    typescriptLogoPlaceholder,
    vueLogoPlaceholder,
    clipStudioLogoPlaceholder,
    recruitTechnologiesLogoPlaceholder,
    henngeLogoPlaceholder,
    vodafoneLogoPlaceholder,
    tempoLogoPlaceholder,
    playcoLogoPlaceholder,
    ticReacToePlaceholder,
    pongPlaceholder,
    sachaPortfolioPlaceholder,
  } = useStaticQuery(
    graphql`
      query GetPortfolioImages {
        tenguPicturePlaceholderDesktop: file(relativePath: { eq: "tengu-picture.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        tenguPicturePlaceholderTablet: file(relativePath: { eq: "tengu-picture.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 1024
            )
          }
        }
        tenguPicturePlaceholderMobile: file(relativePath: { eq: "tengu-picture.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 640
            )
          }
        }
        riverPicturePlaceholderDesktop: file(relativePath: { eq: "river-picture.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        riverPicturePlaceholderTablet: file(relativePath: { eq: "river-picture.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP, AVIF]
              width: 1024
            )
          }
        }
        riverPicturePlaceholderMobile: file(relativePath: { eq: "river-picture.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP, AVIF]
              width: 640
            )
          }
        }
        girlBirdPicturePlaceholderDesktop: file(relativePath: { eq: "girl-bird-picture.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        girlBirdPicturePlaceholderTablet: file(relativePath: { eq: "girl-bird-picture.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP, AVIF]
              width: 1024
            )
          }
        }
        girlBirdPicturePlaceholderMobile: file(relativePath: { eq: "girl-bird-picture.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP, AVIF]
              width: 640
            )
          }
        }
        codingPicPlaceholder: file(relativePath: { eq: "Coding.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 600
            )
          }
        }
        css3LogoPlaceholder: file(relativePath: { eq: "css3-logo.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
              height: 250
            )
          }
        }
        cypressLogoPlaceholder: file(relativePath: { eq: "cypress-logo.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
        html5LogoPlaceholder: file(relativePath: { eq: "html5-logo.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
        javascriptLogoPlaceholder: file(relativePath: { eq: "javascript-logo.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
        jestLogoPlaceholder: file(relativePath: { eq: "jest-logo.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
        nodeLogoPlaceholder: file(relativePath: { eq: "node-logo.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
        reactLogoPlaceholder: file(relativePath: { eq: "react-logo.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
        reduxLogoPlaceholder: file(relativePath: { eq: "redux-logo.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
        typescriptLogoPlaceholder: file(relativePath: { eq: "typescript-logo.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
        vueLogoPlaceholder: file(relativePath: { eq: "vue-logo.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
        clipStudioLogoPlaceholder: file(relativePath: { eq: "celsys-logo.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
        recruitTechnologiesLogoPlaceholder: file(relativePath: { eq: "recruit-technologies-logo.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
        henngeLogoPlaceholder: file(relativePath: { eq: "hennge-logo.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
        vodafoneLogoPlaceholder: file(relativePath: { eq: "vodafone-logo.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
        tempoLogoPlaceholder: file(relativePath: { eq: "tempo-logo.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
        playcoLogoPlaceholder: file(relativePath: { eq: "playco-logo.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
        ticReacToePlaceholder: file(relativePath: { eq: "tic-reac-toe.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
        pongPlaceholder: file(relativePath: { eq: "pong.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
        sachaPortfolioPlaceholder: file(relativePath: { eq: "sacha-portfolio.png"}) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 250
            )
          }
        }
      }
    `,
  );

  const codingImage = getImage(codingPicPlaceholder);

  const tenguSources = [
    getImage(tenguPicturePlaceholderMobile),
    {
      ...getImage(tenguPicturePlaceholderTablet),
      media: '(min-width: 640px)',
    },
    {
      ...getImage(tenguPicturePlaceholderDesktop),
      media: '(min-width: 1024px)',
    },
  ];

  const riverSources = [
    getImage(riverPicturePlaceholderMobile),
    {
      ...getImage(riverPicturePlaceholderTablet),
      media: '(min-width: 640px)',
    },
    {
      ...getImage(riverPicturePlaceholderDesktop),
      media: '(min-width: 1024px)',
    },
  ];

  const girlBirdSources = [
    getImage(girlBirdPicturePlaceholderMobile),
    {
      ...getImage(girlBirdPicturePlaceholderTablet),
      media: '(min-width: 640px)',
    },
    {
      ...getImage(girlBirdPicturePlaceholderDesktop),
      media: '(min-width: 1024px)',
    },
  ];

  return {
    codingImage,
    girlBirdSources,
    riverSources,
    tenguSources,
    css3Logo: getImage(css3LogoPlaceholder),
    html5Logo: getImage(html5LogoPlaceholder),
    cypressLogo: getImage(cypressLogoPlaceholder),
    javascriptLogo: getImage(javascriptLogoPlaceholder),
    jestLogo: getImage(jestLogoPlaceholder),
    nodeLogo: getImage(nodeLogoPlaceholder),
    reactLogo: getImage(reactLogoPlaceholder),
    reduxLogo: getImage(reduxLogoPlaceholder),
    typescriptLogo: getImage(typescriptLogoPlaceholder),
    vueLogo: getImage(vueLogoPlaceholder),
    celsysLogo: getImage(clipStudioLogoPlaceholder),
    recruitTechnologiesLogo: getImage(recruitTechnologiesLogoPlaceholder),
    henngeLogo: getImage(henngeLogoPlaceholder),
    vodafoneLogo: getImage(vodafoneLogoPlaceholder),
    tempoLogo: getImage(tempoLogoPlaceholder),
    playcoLogo: getImage(playcoLogoPlaceholder),
    ticReacToe: getImage(ticReacToePlaceholder),
    pong: getImage(pongPlaceholder),
    sachaPortfolio: getImage(sachaPortfolioPlaceholder),
  };
}
