import { css } from 'styled-components';

export const animationRunOnceInit = (): (animation: string, doRun: boolean) => string => {
  let hasRun = false;

  return (animation, doRun) => {
    if (!hasRun && doRun) {
      hasRun = true;
      return css`animation: ${animation}`;
    }
    return '';
  };
};

export const doNothingFakUTS = (): void => { };
