import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { useInView } from 'react-intersection-observer';
import { css } from 'twin.macro';
import { useEffect, useState } from 'react';
import getPortfolioImages from '../components/hooks/getPortfolioImages';
import * as Styled from '../components/styled/indexPage';
import {
  CenteredSectionTitle,
  FirstSectionTitle,
  HeroWaveContainer,
  InvertedOuterContentDeepChampagne,
  MainParagraph,
  OuterContentBabyBlueSnake,
  OuterContentPeachOrange,
  ParagraphContainer,
  RightSideHero,
  SectionTitle,
  SmallH1,
  StackGrid,
  StackImageCaption,
  StackImageContainer,
  StackImageGI,
  FinalSection,
  Footer,
  HeaderNav,
  HeaderNavItem,
  BlogLink,
} from '../components/styled/indexPage';
import { Query } from '../../graphql-types';
import * as colors from '../utils/Colors';
import HeroWave from '../components/svg/HeroWave';
import { slideInRight, slowBounce, slideInLeft } from '../components/styled/animations';
import ProjectItem from '../components/ProjectItem';
import Seo from '../components/Seo';

const {
  GlobalStyle,
  Header,
  Hero,
  Parallax,
  StyledBackground,
  FirstOuterContent,
  Content,
  FlexContainer,
  LeftSideHero,
  SkewedContent,
  InvertedSkewedContent,
  FirstContent,
} = Styled;

const isBrowser = typeof window !== 'undefined';

type Props = {
  data: Query;
};

type AboutMe = {
  body: string;
  fields: {
    collection: 'aboutme';
  };
};

type TechStack = {
  fields: {
    collection: 'techstack';
  };
  frontmatter: {
    techname: string;
    imagetype: string;
  };
};

export type Jobs = {
  body: string;
  fields: {
    collection: 'mainjobs' | 'sidejobs';
  };
  frontmatter: {
    title: string;
    position: string;
    startdate: string;
    link?: string;
    enddate?: string;
    image: {
      relativePath: string;
    };
    backgroundcolor?: string;
  };
};

type ReducedAllMdx = {
  aboutme: AboutMe[];
  techstack: TechStack[];
  mainjobs: Jobs[];
  sidejobs: Jobs[];
};

type State = {
  hasRunTechStackAnimation: boolean;
  hasRunMainProjectsAnimation: boolean;
  hasRunSideProjectsAnimation: boolean;
};

const getImageName = (image: string): string => image
  .match(/^[a-zA-Z0-9-]*/)[0]
  .split('-')
  .map((str, i) => (i !== 0 ? str.substr(0, 1).toUpperCase() + str.substr(1) : str))
  .join('');

const useSetState = () => {
  const [state, setStateInternal] = useState<State>({
    hasRunTechStackAnimation: false,
    hasRunMainProjectsAnimation: false,
    hasRunSideProjectsAnimation: false,
  });

  const setState = (newState: Partial<State>) => {
    setStateInternal((oldState) => ({
      ...oldState,
      ...newState,
    }));
  };

  return { state, setState };
};

const mql = isBrowser ? window.matchMedia('(min-width: 1024px)') : null;

const IndexPage = (props: Props): JSX.Element => {
  const { state, setState } = useSetState();
  const { ref: aboutMeRef, inView: aboutMeInView } = useInView();
  const { ref: techStackRef, inView: techStackInView } = useInView();
  const { ref: mainProjectRef, inView: mainProjectInView } = useInView();
  const { ref: sideProjectRef, inView: sideProjectInView } = useInView();

  const { hasRunTechStackAnimation, hasRunMainProjectsAnimation, hasRunSideProjectsAnimation } = state;

  useEffect(() => {
    if (techStackInView) {
      setState({
        hasRunTechStackAnimation: true,
      });
    }
  }, [techStackInView]);

  useEffect(() => {
    if (mainProjectInView) {
      setState({
        hasRunMainProjectsAnimation: true,
      });
    }
  }, [mainProjectInView]);

  useEffect(() => {
    if (sideProjectInView) {
      setState({
        hasRunSideProjectsAnimation: true,
      });
    }
  }, [sideProjectInView]);

  if (!isBrowser) return null;
  const {
    aboutme, techstack, mainjobs, sidejobs,
  }: ReducedAllMdx = props.data.allMdx.edges.reduce(
    (res, { node }) => {
      res[node.fields.collection].push(node);
      return res;
    },
    {
      aboutme: [],
      techstack: [],
      mainjobs: [],
      sidejobs: [],
    },
  );

  const latestEdge = aboutme[0];

  const {
    codingImage, riverSources, girlBirdSources, tenguSources, ...otherImages
  } = getPortfolioImages();

  return (
    <>
      <Seo
        title="Sacha Morgese's Homepage"
        description="My portfolio/blog"
        keywords={['sacha morgese\'s portoflio', 'sacha morgese', 'sacha morgese personal page']}
      />
      <GlobalStyle />
      <Header>
        <HeaderNav>
          <HeaderNavItem href="#aboutme">About Me</HeaderNavItem>
          <HeaderNavItem href="#techstack">Teck Stack</HeaderNavItem>
          <HeaderNavItem href="#workexperience">Work Experience</HeaderNavItem>
          <HeaderNavItem href="#sideprojects">Side Projects</HeaderNavItem>
        </HeaderNav>
        <BlogLink to="/blog">Blog</BlogLink>
      </Header>
      <Hero id="top">
        <Content>
          <FlexContainer>
            <LeftSideHero>
              <MainParagraph>Welcome, fellow human</MainParagraph>
              <SmallH1>Sacha Morgese, JS developer</SmallH1>
            </LeftSideHero>
            <RightSideHero>
              <GatsbyImage image={codingImage} alt="coding" />
            </RightSideHero>
          </FlexContainer>
        </Content>
        <HeroWaveContainer>
          <HeroWave fill={colors.lavender} />
        </HeroWaveContainer>
      </Hero>

      <FirstOuterContent>
        <FirstContent>
          <FirstSectionTitle id="aboutme">About Me</FirstSectionTitle>
          <ParagraphContainer ref={aboutMeRef} inView={aboutMeInView}>
            <MDXRenderer>{latestEdge.body}</MDXRenderer>
          </ParagraphContainer>
        </FirstContent>
      </FirstOuterContent>

      <Parallax>
        <StyledBackground image={tenguSources} />
      </Parallax>

      <OuterContentPeachOrange>
        <SkewedContent>
          <CenteredSectionTitle id="techstack">My Stack</CenteredSectionTitle>
          <StackGrid ref={techStackRef}>
            {techstack.map(({ frontmatter: { imagetype, techname } }, index) => {
              const animation = mql.matches
                ? `
                    animation-duration: ${hasRunTechStackAnimation ? '2s' : 0};
                    animation-delay: ${hasRunTechStackAnimation ? `${0.3 * index}s` : 0};
                  `
                : '';

              return (
                <StackImageContainer
                  key={techname}
                  css={`
                    opacity: ${hasRunTechStackAnimation ? 1 : 0};
                  `}
                >
                  <StackImageGI
                    className={`child-${index}`}
                    image={otherImages[`${techname}${imagetype.substr(0, 1).toUpperCase() + imagetype.substr(1)}`]}
                    alt={`${techname} ${imagetype}`}
                    css={css`
                      animation-name: ${slowBounce};
                      animation-timing-function: ease-out;
                      animation-fill-mode: forwards;
                      ${animation}
                    `}
                    $shouldHideImages={mql.matches}
                  />
                  <StackImageCaption>{techname}</StackImageCaption>
                </StackImageContainer>
              );
            })}
          </StackGrid>
        </SkewedContent>
      </OuterContentPeachOrange>

      <Parallax>
        <StyledBackground image={riverSources} />
      </Parallax>

      <InvertedOuterContentDeepChampagne>
        <InvertedSkewedContent ref={mainProjectRef}>
          <SectionTitle id="workexperience">Work Experience</SectionTitle>
          {mainjobs
            .sort((a, b) => new Date(a.frontmatter.startdate).valueOf() - new Date(b.frontmatter.startdate).valueOf())
            .map((job, index): JSX.Element => {
              const slideAnimation = index % 2 !== 0 ? slideInRight : slideInLeft;
              return (
                <div
                  key={job.frontmatter.title}
                  css={css`
                    animation-name: ${hasRunMainProjectsAnimation ? slideAnimation : 'none'};
                    animation-duration: ${hasRunMainProjectsAnimation ? '2s' : 0};
                    animation-delay: ${hasRunMainProjectsAnimation ? `${0.3 * index}s` : 0};
                    animation-timing-function: ease-out;
                    animation-fill-mode: forwards;
                    opacity: 0;
                  `}
                >
                  <ProjectItem
                    index={index}
                    job={job}
                    image={otherImages[getImageName(job.frontmatter.image.relativePath)]}
                  />
                </div>
              );
            })}
        </InvertedSkewedContent>
      </InvertedOuterContentDeepChampagne>

      <Parallax>
        <StyledBackground image={girlBirdSources} />
      </Parallax>

      <OuterContentBabyBlueSnake>
        <SkewedContent ref={sideProjectRef}>
          <SectionTitle id="sideprojects">Side Projects</SectionTitle>
          {sidejobs
            .sort((a, b) => new Date(a.frontmatter.startdate).valueOf() - new Date(b.frontmatter.startdate).valueOf())
            .map((job, index): JSX.Element => {
              const slideAnimation = index % 2 !== 0 ? slideInRight : slideInLeft;
              return (
                <div
                  key={job.frontmatter.title}
                  css={css`
                    animation-name: ${hasRunSideProjectsAnimation ? slideAnimation : 'none'};
                    animation-duration: ${hasRunSideProjectsAnimation ? '2s' : 0};
                    animation-delay: ${hasRunSideProjectsAnimation ? `${0.3 * index}s` : 0};
                    animation-timing-function: ease-out;
                    animation-fill-mode: forwards;
                    opacity: 0;
                  `}
                >
                  <ProjectItem
                    index={index}
                    job={job}
                    image={otherImages[getImageName(job.frontmatter.image.relativePath)]}
                  />
                </div>
              );
            })}
        </SkewedContent>
      </OuterContentBabyBlueSnake>

      <Parallax />

      <FinalSection>
        <Footer>
          <a href="#top">Top &#8593;</a>
          <span>Copyright &copy; 2021 Sacha Morgese</span>
        </Footer>
      </FinalSection>
    </>
  );
};

export const pageQuery = graphql`
  query IndexPageQuery {
    allMdx(
      sort: { fields: rawBody }
      filter: { fields: { collection: { in: ["aboutme", "techstack", "mainjobs", "sidejobs"] } } }
    ) {
      edges {
        node {
          body
          fields {
            collection
          }
          frontmatter {
            link
            techname
            imagetype
            image {
              relativePath
            }
            title
            position
            startdate
            enddate
            backgroundcolor
          }
        }
      }
    }
  }
`;

export default IndexPage;
