import * as React from 'react';
import tw, { styled } from 'twin.macro';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { ReactElement } from 'react';
import { Jobs } from '../pages';
import Paragraph from './typography/Paragraph';

const ProjectExternalContainerLeft = tw.div`flex w-full flex-col lg:flex-row-reverse text-right mb-4`;
const ProjectExternalContainerRight = tw(ProjectExternalContainerLeft)`lg:flex-row text-left`;
const ProjectInternalText = tw.div`lg:w-2/3`;
const ProjectInternalImage = tw.div`lg:w-1/3 flex items-center justify-center`;
const ProjectImage = styled(GatsbyImage).attrs(({ backgroundColor }) => ({
  imgStyle: { objectFit: 'contain', padding: backgroundColor ? 10 : 0, borderBottom: '0 !important' },
}))`
  ${tw`mx-auto my-0 block w-64 border-0 h-64 rounded-lg`}
  ${({ backgroundColor }) => `background-color: ${backgroundColor || '#FFF'}`}
`;
const DateParagraph = tw(Paragraph)`mb-4`;
const ParagraphContainer = tw.div`text-justify`;

type JobType = Jobs;

type ProjectItem = {
  index: number;
  job: JobType;
  image: IGatsbyImageData;
};

const formatDate = (date: string): string => new Date(date).toLocaleString('en-US', {
  year: 'numeric',
  month: 'long',
});

const ProjectItem = ({ index, job, image }: ProjectItem): JSX.Element => {
  const ProjectExternalContainer = index % 2 !== 0 ? ProjectExternalContainerLeft : ProjectExternalContainerRight;

  const {
    body,
    frontmatter: {
      title, position, startdate, enddate, backgroundcolor, link,
    },
  } = job;

  const ProjectLink = ({ children }: { children: ReactElement }): ReactElement => {
    if (link) {
      return (
        <a href={link} target="_blank" rel="noreferrer">
          {children}
        </a>
      );
    }
    return children;
  };

  const nowOrNothing = link ? '' : ' - now';

  return (
    <ProjectExternalContainer>
      <ProjectInternalImage>
        <ProjectLink>
          <ProjectImage image={image} alt={title} backgroundColor={backgroundcolor} />
        </ProjectLink>
      </ProjectInternalImage>
      <ProjectInternalText>
        <ProjectLink>
          <h3>{title}</h3>
        </ProjectLink>
        {position ? <h4 css={tw`normal-case my-4`}>{position}</h4> : ''}
        <DateParagraph>
          {`${formatDate(startdate)}${
            enddate ? ` - ${formatDate(enddate)}` : nowOrNothing
          }`}
        </DateParagraph>
        <div>
          <ParagraphContainer>
            <MDXRenderer>{body}</MDXRenderer>
          </ParagraphContainer>
        </div>
      </ProjectInternalText>
    </ProjectExternalContainer>
  );
};

export default ProjectItem;
