export const waterspout = '#94F4F4';
export const lightCyan = '#E1FDFD';
export const white = '#FFFFFF';
export const turquoise = '#56EAEA';
export const brightTurquoise = '#14E5E5';
export const babyBlueEyes = '#9FB9F6';
export const lavender = '#E4ECFE';
export const cornflowerBlue = '#6790EE';
export const royalBlue = '#2B65EA';
export const deepChampagne = '#F6DC9F';
export const oldLace = '#FFF5E2';
export const crayola = '#FFC95D';
export const darkTangerine = '#FFB116';
export const peachOrange = '#FFC89B';
export const linen = '#FFEFE2';
export const rajah = '#FFA65D';
export const pumpkin = '#FF8016';
