import tw, { css, styled, theme } from 'twin.macro';
import { BgImage } from 'gbimage-bridge';
import { createGlobalStyle, keyframes } from 'styled-components';
import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import * as colors from '../../../utils/Colors';
import { animationRunOnceInit } from '../utils';
import { slideInLeft, slowDescent } from '../animations';

export const GlobalStyle = createGlobalStyle`
  :root {
    --baby-blue-eyes: ${colors.babyBlueEyes};
    --peach-orange: ${colors.peachOrange};
    --deep-champagne: ${colors.deepChampagne};
    --lavender: ${colors.lavender};
  }

  html, body {;
    ${tw`text-gray-800 box-border overflow-hidden h-screen`};
  }

  body {
    ${tw`h-screen relative overflow-x-hidden overflow-y-auto font-family['Montserrat,sans-serif'] perspective[0]
    lg:perspective[1] lg:transform-style[preserve-3d]`}
  }

  body > div, body > div > div, body .parallax {
    ${tw`lg:transform-style[preserve-3d]`}
  }

  a, a *, a:active, a:active *, a:hover, a:hover *, a:link, a:link *, a:visited, a:visited * {
    text-decoration: none;
    color: inherit;
    box-shadow: none;
  }
`;

const SharedHeadingText = 'font-h1 font-black leading-none inline-block';

const paragraphContainerAnimation = animationRunOnceInit();

const TextBlinking = keyframes`
  0% { opacity: 1 }
  50% { opacity: 0 }
  100% { opacity: 1 }
`;

export const ParagraphContainer = styled.div`
  ${tw`text-xl xl:text-2xl lg:w-1/2 lg:ml-auto`};
  ${({ inView }) => paragraphContainerAnimation(
    css`
        ${TextBlinking} 2s ease-in-out
      `,
    inView,
  )}
`;

export const MainParagraph = styled.p`
  ${tw`${SharedHeadingText} text-6xl lg:text-7xl`};
  animation: ${slowDescent} 2s ease-out;
`;

export const SmallH1 = styled.h1`
  ${tw`${SharedHeadingText} text-3xl`};
  animation: ${slideInLeft} 2s ease-out;
`;

export const SectionTitle = tw.h2`${SharedHeadingText} text-3xl lg:text-5xl mb-16 mt-20`;
export const RightedSectionTitle = tw(SectionTitle)`lg:w-1/2 lg:text-right lg:pr-8 mt-0`;
export const CenteredSectionTitle = tw(SectionTitle)`w-full sm:text-center mt-0 sm:mt-10 lg:mt-20`;
export const FirstSectionTitle = tw(RightedSectionTitle)`mt-20`;

export const Header = tw.header`h-20 bg-transparent absolute w-full md:shadow-md z-20 top-0 flex justify-between
items-center px-4`;
export const HeaderNav = tw.nav`font-family['Merriweather','Georgia',serif] invisible md:visible md:text-lg w-1/2
md:w-auto`;
export const HeaderNavItem = tw.a`mr-4 font-bold`;
export const BlogLink = tw(Link)`w-36 h-12 bg-white rounded-lg flex justify-center items-center md:text-lg
font-family['Merriweather','Georgia',serif] font-bold`;

export const Hero = tw.div`w-screen min-h-screen overflow-hidden relative pt-20 md:pt-40 z-10 pb-40
background[linear-gradient(90deg, rgba(0,212,255,1) 0%, rgba(148,244,244,1) 100%)]`;

export const HeroWaveContainer = tw.div`absolute bottom-0 w-full`;

export const Parallax = tw.div`w-screen h-half lg:h-75-vh xl:h-screen transform-style[preserve-3d]`;

export const StyledBackground = styled(BgImage)`
  ${tw`w-screen left-0 h-full lg:-mt-28 z-[-1]`};

  @media (min-width: ${theme`screens.lg`}) {
    ${tw`absolute! top-0`}
    transform: translateZ(-1px) scale(2);
  }
`;

export const OuterContent = styled.section`
  @media (min-width: 1024px) {
    transform: skewY(3deg);
  }
  ${tw`bg-white min-h-130-vh z-10 relative relative pt-20 pb-36 lg:-mt-40`}
`;

export const OuterContentBabyBlueSnake = tw(OuterContent)`background-color[var(--baby-blue-eyes)]`;

export const OuterContentPeachOrange = tw(OuterContent)`background-color[var(--peach-orange)]`;

export const InvertedOuterContent = styled(OuterContent)`
  @media (min-width: 1024px) {
    transform: skewY(-3deg);
  }
  ${tw`bg-white`}
`;

export const InvertedOuterContentDeepChampagne = tw(InvertedOuterContent)`background-color[var(--deep-champagne)]`;

export const FirstOuterContent = styled(OuterContent)`
  @media (min-width: 1024px) {
    transform: skewY(-3deg);
  }
  ${tw`text-justify md:text-left pt-0 lg:pt-20 -mt-5 md:-mt-8 lg:-mt-20 2xl:-mt-28 min-h-screen
  background-color[var(--lavender)]`}
`;

export const Content = tw.div`my-0 mx-auto transform-gpu w-full px-5
  sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl
`;

export const FlexContainer = tw.div`md:flex`;

export const LeftSide = tw.div`flex-grow w-full`;

export const LeftSideHero = tw(LeftSide)`flex flex-col justify-center`;

export const RightSide = tw.div`flex-grow w-full`;

export const RightSideHero = tw(RightSide)`mt-10 lg:mt-0`;

export const SkewedContent = styled(Content)`
  @media (min-width: 1024px) {
    transform: skewY(-3deg);
  }`;

export const InvertedSkewedContent = styled(Content)`
  @media (min-width: 1024px) {
    transform: skewY(3deg);
  }`;

export const FirstContent = styled(Content)`
  @media (min-width: 1024px) {
    transform: skewY(3deg);
  }`;

export const StackGrid = tw.div`mt-20 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5`;

export const StackImageContainer = tw.figure`mb-20 relative h-80`;

export const StackImageGI = styled(GatsbyImage).attrs(() => ({
  imgStyle: { objectFit: 'contain' },
}))`
  ${tw`mx-auto my-0 block`}
  ${({ $shouldHideImages }) => `opacity: ${$shouldHideImages ? '0' : '1'}`}
`;

export const StackImageCaption = tw.figcaption`block w-full text-center text-3xl text-gray-700 mt-4
font-semibold capitalize bottom-0 absolute`;

export const FinalSection = tw.section`relative`;

export const Footer = tw.footer`h-16 absolute bottom-0 w-full shadow border-solid border-0 border-t-2 border-gray-300
text-center text-base flex align-bottom justify-between items-end font-family['Merriweather','Georgia',serif] p-4`;
